import React from 'react';
import axios from 'axios';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import config from 'react-global-configuration';

class App extends React.Component {
 
  constructor(props) {
      super(props);
      this.state = { vimage: [], loaded: false };
  }
 
  render() {
 //console.log(this.state.vimage)
   
      return (
        <img className="w-100" src="https://cdn.neurolage.com/retailpacx/casipillai/retailpacx-1619753599592.jpeg" />

               )
  }
}

export default App;
