import React from 'react';
import {  Carousel } from 'react-bootstrap';
import BannerImage from "./bannerimage";

class App extends React.Component {

  constructor(props) {
      super(props);
      this.state = { carouselitems: JSON.parse(this.props.params.ItemData) };
  }

  componentDidMount() {
    this._isMounted = true;
      this.setState({ carouselitems:  this.state.carouselitems })
}

  render() {

   
      return (
        <div className="mainbanner">
        <Carousel  >
       
 

        {

          
        this.state.carouselitems.Carousel.map((carousel, index) => (
          
          <Carousel.Item key={'Carousel'+index}>
           <BannerImage params={{ ItemData: carousel.banner, className : ''}}  />
          <Carousel.Caption>
        <h3>{carousel.heading} </h3>
            <p>{carousel.desc}</p>
          </Carousel.Caption>
        </Carousel.Item>

))}

     




      </Carousel>
      </div>
      )
  }
}

export default App;
