
import React, { Component } from 'react';
import {Progress} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import config from 'react-global-configuration';
import "./config.js";
import jwt from 'jsonwebtoken';
import { renderIntoDocument } from 'react-dom/test-utils';

class Application extends Component {
  constructor(props) {
    super(props);
      this.state = {
        selectedFile: null,
        videoTitle: this.props.params.MenuItemId,
        videoBody: this.props.params.MenuItem,
        loaded:0
      }
   
  }
  checkMimeType=(event)=>{
    //getting file object
    let files = event.target.files 
    //define message container
    let err = []
    // list allow mime type
   const types = ['video/mp4', 'video/mov', 'video/avi', 'video/webm']
    // loop access array
    for(var x = 0; x<files.length; x++) {
     // compare file type find doesn't matach
         if (types.every(type => files[x].type !== type)) {
         // create error message and assign to container   
         err[x] = files[x].type+' is not a supported format\n';
       }
     };
     for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
         // discard selected file
        toast.error(err[z])
        event.target.value = null
    }
   return true;
  }
  maxSelectFile=(event)=>{
    let files = event.target.files
        if (files.length > 3) { 
           const msg = 'Only 3 videos can be uploaded at a time'
           event.target.value = null
           toast.warn(msg)
           return false;
      }
    return true;
 }
 checkFileSize=(event)=>{
  let files = event.target.files
  let size = 20000000 
  let err = []; 
  for(var x = 0; x<files.length; x++) {
  if (files[x].size > size) {
   err[x] = files[x].type+'is too large, please pick a smaller file\n';
 }
};
for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
  // discard selected file
 toast.error(err[z])
 event.target.value = null
}
return true;
}
onChangeHandler=event=>{
  var files = event.target.files
  if(this.maxSelectFile(event) && this.checkMimeType(event) &&    this.checkFileSize(event)){ 
  // if return true allow to setState
     this.setState({
     selectedFile: files,
     loaded:0
  })
}
}

onChangeVideoHandler = event =>{
  var title = event.target.value
     this.setState({
      videoTitle: title 
  })
}
onChangeBodyTetxHandler = event =>{
  var videoBody = event.target.value
     this.setState({
      videoBody: videoBody 
  })
}
  onClickHandler = () => {

    if(!this.state.selectedFile){
     
    const axconfig = {
      headers: {
          'content-type': 'multipart/form-data'
      }
  };
  
    const pageData = {
      itemheading: this.state.videoTitle,
      item: this.state.videoBody,
      width: this.props.params.w,
      height: this.props.params.h,
      x: this.props.params.x,
      y: this.props.params.y,
      type:'video',
      itemdata : this.props.params.MenuItemData,
      pageid:this.props.params.pageid,
      companyid: sessionStorage.getItem('company')
  }
  
  const timestamp = Math.floor(Date.now() / 1000) + 1000;
  var payload = {
    "aud": process.env.REACT_APP_jwtAud,
    "exp": timestamp,
    "iss": process.env.REACT_APP_jwtIss
  };

  var token = jwt.sign(payload, process.env.REACT_APP_secret);

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer `+token,
    }  };

    axios.post(config.get('backendURL') + 'homelayout/update/'+this.props.params.itemid, pageData,{
    onUploadProgress: ProgressEvent => {
      this.setState({
        loaded: (ProgressEvent.loaded / ProgressEvent.total*100),
      })
    },
  }, requestOptions)
    .then(function (data) {
      toast.success('upload success')
      console.log(data)
      if(data.statusText=='OK'){
     //  window.location.href="/home"
      }
    })
    .catch(err => { // then print response status
      toast.error('upload fail')
    })

  

      
      return;
    }
    const data = new FormData() 
    for(var x = 0; x<this.state.selectedFile.length; x++) {
      data.append('retailpacx', this.state.selectedFile[x])
    }
    data.append('foldername', sessionStorage.getItem('companybrandname'))
    data.append('videoTitle', this.state.videoTitle)
    data.append('videoBody',  this.state.videoBody)

    const axconfig = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };

  
    axios.post(config.get('backendURL') + 'homelayout/uploadvideo/'+this.props.params.itemid,data,axconfig)
    .then(res =>
  {

    console.log(res)

      const pageData = {
        itemheading: this.state.videoTitle,
        item: this.state.videoBody,
        width: this.props.params.w,
        height: this.props.params.h,
        x: this.props.params.x,
        y: this.props.params.y,
        type:'video',
        itemdata : res.data.imageName,
        pageid:this.props.params.pageid,
        companyid: sessionStorage.getItem('company')
    }
    
    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      "aud": process.env.REACT_APP_jwtAud,
      "exp": timestamp,
      "iss": process.env.REACT_APP_jwtIss
    };
 
    var token = jwt.sign(payload, process.env.REACT_APP_secret);
 
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer `+token,
      }  };

      axios.post(config.get('backendURL') + 'homelayout/update/'+this.props.params.itemid, pageData,{
      onUploadProgress: ProgressEvent => {
        this.setState({
          loaded: (ProgressEvent.loaded / ProgressEvent.total*100),
        })
      },
    }, requestOptions)
      .then(function (data) {
        toast.success('upload success')
        console.log(data)
        if(data.statusText=='OK'){
       //  window.location.href="/home"
        }
      })
      .catch(err => { // then print response status
        toast.error('upload fail')
      })

     
    }
       );  

 
    }
 
  render() {
    const { crop, croppedImageUrl, src } = this.state;
    return (
      <div class="container">
      <div class="row">
        <div class="offset-md-3 col-md-6">

        <div class="form-group files">
              <label>Video Title </label>
              <input type="text" class="form-control" defaultValue={this.props.params.MenuItemId} onChange={this.onChangeVideoHandler} />
            </div>  

            <div class="form-group files">
              <label>Body Text </label>
              <textarea class="form-control" onChange={this.onChangeBodyTetxHandler} >{this.props.params.MenuItem}</textarea>
            </div>  


             <div class="form-group files">
              <label>Upload Your File </label>
              <input type="file" class="form-control" multiple onChange={this.onChangeHandler}/>
            </div>  
            <div class="form-group">
            <ToastContainer />
            <Progress max="100" color="success" value={this.state.loaded} >{Math.round(this.state.loaded,2) }%</Progress>
      
            </div> 
            
            <button type="button" class="btn btn-success btn-block" onClick={this.onClickHandler}>Upload</button>

      </div>
    </div>
    </div>
    );
  }
}
export default Application;
 