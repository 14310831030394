import React, { Component } from 'react';


export default class header extends Component {
  constructor(props) {
    super(props);
   }

  componentDidMount() {
      
  }

  render() {

    return (
        <div style={{ textAlign: 'center' }}> ©2020 Created by Ecologital</div>
       );
    }
  }