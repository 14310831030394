import React, { Component } from 'react';
import { Button, Table, Alert, Row, Col} from 'react-bootstrap';
import { Input, Form, Layout, Select, Menu, Drawer, Space } from 'antd';
import axios from 'axios';
import CompanyDetails from "./company_new";
import config from 'react-global-configuration';
import {
    EditOutlined, AimOutlined, EllipsisOutlined
} from '@ant-design/icons';



const Site = props => (
    <tr>
    <td><input type="checkbox" /></td>
    <td>{props.site.Name}</td>
    <td>{props.site.Name}<br/>{props.site.CustomerDomain}<br/>{props.site.SubDomain}</td>
    <td align="center"><a href={'/sites/'+props.site._id}  ><EditOutlined style={{ fontSize: '20px', color: '#08c' }} /> </a> </td>
    <td align="center"><a href={'/preview/'+props.site._id} target="_blank"><AimOutlined style={{ fontSize: '20px', color: '#08c' }} /> </a></td>
    <td align="center"><EllipsisOutlined style={{ fontSize: '20px', color: '#08c' }} /></td>
  </tr>
   
  )
export default class Company extends Component {
  constructor(props) {
    super(props);
    this.state = { sites: [] };
  }
  onCompanySelect = () => {
    window.location.reload(); 
};
  componentDidMount() {
    const requestOne = axios.get(config.get('backendURL') + 'company/')
    .then(response => {
      this.setState({ sites: response.data })
    })
    .catch((error) => {
      console.log(error);
    })
  }
  showChildrenDrawer = () => {
    this.setState({
      childrenDrawer: true,
    });
  };
  onChildrenDrawerClose = () => {
    this.setState({
      childrenDrawer: false,
    });
  };
 siteList() {
    return this.state.sites.map(currentpage => {
      return <Site site={currentpage} key={currentpage._id} />;
    })
  } 

  render() {
 
    return (
      <>
        <Row>
            <Col sm={12}>&nbsp;</Col>
            <Col sm={1}></Col>
           <Col sm={10}>

           

        <Alert variant="light">
        <Alert.Heading>Sites Dashboard</Alert.Heading>

         <Table striped bordered hover>
        <thead>
          <tr>
             
            <th colSpan="3"> 
    SITES
  </th>
  <th width="10%" align="center"> 
  Edit
  </th>
  <th width="10%" align="center"> 
    Preview
  </th>
  <th width="10%" align="center"> 
  <Button type="primary" onClick={this.showChildrenDrawer}>
          Add New site
        </Button>
  </th>
          </tr>
        </thead>
        <tbody>
          
        {this.siteList()}
          
           
        </tbody>
      </Table>
      </Alert>
      </Col>
      <Col sm={1}></Col>
        </Row>
        <Drawer
        title="COMPANY DETAILS"
        height={860}
        closable={true}
        placement="top"
        onClose={this.onChildrenDrawerClose}
        visible={this.state.childrenDrawer}
      >

        <CompanyDetails />
      </Drawer>
</>
    );
  }
}
