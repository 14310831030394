import React, { Component } from 'react';
import { Popover, Modal, Drawer, Input } from 'antd';
import axios from 'axios';
import { Form, Col, Button } from 'react-bootstrap';
import { DragOutlined, PlusOutlined, SettingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import config from 'react-global-configuration';
const { confirm } = Modal;

export default class Company extends Component {
  constructor(props) {
    super(props);
    this.onChangeMenuName = this.onChangeMenuName.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    const datax = [];

    this.state = {
      menuname: '',
      description: '',
      type: 'main',
      isfooteritem: false,
      companyid: '',
      datax, menus: []
    }

  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  componentDidMount() {
    axios.get(config.get('backendURL') + 'menus/' + sessionStorage.getItem('company'))

      .then(response => {
        this.setState({ datax: response.data })
      })
      .catch((error) => {
        console.log(error);
      })
  }

  showMenuDrawer = () => {
    this.setState({
      MenuVisible: true
    });
  };
  onMenuClose = () => {
    this.setState({
      MenuVisible: false,
    });
  };
  state = { MenuVisible: false };

  onChangeMenuName(e) {
    this.setState({
      menuname: e.target.value
    })
  }
  onChangeDescription(e) {
    this.setState({
      description: e.target.value
    })
  }

  handleCancel = () => {
    this.setState({ visible: false });
  };
  onChangeType(e) {
    if (e.target.value == "main") {
      this.setState({
        type: "main",
        isfooteritem: false,
      })
    } else {
      this.setState({
        isfooteritem: true,
        type: ""
      })
    }

  }
  onPopClick = (id, description,type, isfooteritem, menuname) => {
    this.setState({ clickedid: id, description:description, type:type , isfooteritem:isfooteritem , menuname: menuname});
  };
  handleOk = (id) => {

    if(!document.getElementById("clicked"+id).value){
    Modal.error({
      title: 'Error',
      content: 'Please enter a value',
      onOk() {
      },
    })
  }else{

    var updatemenuitems = {
      menuname: document.getElementById("clicked"+id).value,
      description: document.getElementById("clickedd"+id).value,
      type: this.state.type,
      isfooteritem: this.state.isfooteritem,
      companyid: sessionStorage.getItem('company'),
    }
    console.log(updatemenuitems)
    axios.post(config.get('backendURL') + 'menus/update/' + id, updatemenuitems)
    .then(function (data) {

      Modal.success({
        title: 'Success',
        content: 'Menu renamed',
        onOk() { 
          window.location.href = '/home';
        },
      });


    })
 
  }
  };
 
  onPopClickDelete = (id) => {
    this.setState({ clickedid: id });

    confirm({
      title: 'Are you sure delete this menu?',
      icon: <ExclamationCircleOutlined />,
      content: 'You can not restore this item once you delete it, All the pages under these menu will get deleted and can not recover',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        axios.delete(config.get('backendURL') + 'menus/'+id)
        .then(response => {
          Modal.success({
            title: 'Success',
            content: 'Menu deleted',
            onOk() { 
              window.location.href = '/home';
            },
          }) 
        })
        .catch((error) => {
          console.log(error);
        })
      },
      onCancel() {
        console.log('Cancel');
      },
    });

  
     
    
  };
  onSubmit(e) {
    e.preventDefault();
    const fullmenu = '';



    const menu = {
      menuname: this.state.menuname,
      description: this.state.description,
      type: this.state.type,
      isfooteritem: this.state.isfooteritem,
      companyid: sessionStorage.getItem('company'),
    }


    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(menu)
    };
    console.log(JSON.stringify(requestOptions))

    fetch(config.get('backendURL') + 'menus/add', requestOptions)
      .then(res => console.log(res.data));

    Modal.success({
      title: 'Success',
      content: 'Menu has been added',
      onOk() {
        window.location.href = '/home';
      },
    });



  }

  render() {
    const { visible, loading} = this.state;

    const content = (
      <div>
        <p><a href='#' onClick={this.showModal}>Rename</a>

          <Modal
            visible={visible}
            title="Rename Page"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>
                Return
              </Button>,
              <Button key="submit" type="primary" loading={loading} onClick={this.handleOk.bind(this, this.state.clickedid )}>
                Submit
              </Button>,
            ]}
          >
            <Input id={'clicked'+this.state.clickedid} placeholder="Menu Title" defaultValue={this.state.menuname}  />
           Renaming this page does not change the URL. To change it, go to Page URL.

           <Input id={'clickedd'+this.state.clickedid} placeholder="Menu Description" defaultValue={this.state.description} />
          Page Description.


          </Modal>
        </p>
        <p><a href='#' onClick={this.onPopClickDelete.bind(this, this.state.clickedid)} >Delete</a></p>
      </div>


    );
    return (

      <>
        <Button type="primary" shape="round" onClick={() => this.showMenuDrawer()} icon={<PlusOutlined />}>
          Add New Menu
        </Button>

        <ul className="ant-list-items">
          {this.state.datax.map((item, index) => (
            <li key={index} className="ant-list-item">
              <div >
                <div className="ant-list-item-meta-avatar"> <a href="#"><DragOutlined style={{ fontSize: '15px' }} /></a></div>
              </div>
              <div className="ant-list-item-meta-content"><div className="ant-list-item-meta-description">{item.menuname}</div></div>
              <div>

                <Popover placement="right" title={item.menuname} content={content}  trigger="focus" onClick={this.onPopClick.bind(this, item._id, item.description, item.type, item.isfooteritem, item.menuname)}>
                  <SettingOutlined />
                </Popover>
              </div>
              <hr />

            </li>
          ))}
        </ul>

        <Drawer
          title="Add New Menu"
          width={720}
          placement="left"
          closable={true}
          onClose={this.onMenuClose}
          visible={this.state.MenuVisible}
        >

          <Form onSubmit={this.onSubmit}>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridMenuName">
                <Form.Label>Menu Name</Form.Label>
                <Form.Control required type="text" onChange={this.onChangeMenuName} placeholder="Menu Name" />
              </Form.Group>

            </Form.Row>

            <Form.Row>

              <Form.Group as={Col} controlId="formGridDesc">
                <Form.Label>Description</Form.Label>
                <Form.Control required type="text" onChange={this.onChangeDescription} placeholder="Description" />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridType">

                <Form.Label>Menu Type</Form.Label>
                <Form.Control as="select" onChange={this.onChangeType}>
                  <option value="main">Main</option>
                  <option value="footer">Footer</option>
                </Form.Control>

              </Form.Group>
            </Form.Row>


            <Button variant="primary" type="submit">
              Submit
  </Button>
          </Form>



        </Drawer>



      </>



    );
  }
}
