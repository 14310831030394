
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button, Modal } from 'antd';
import './App.css';
import axios from 'axios';
import * as bodyParser from 'body-parser';
import config from 'react-global-configuration';
import "./config.js";

class Application extends React.Component {
  state = {
    src: null,
    disabled: false,
    crop: {
      unit: '%',
      width: 30,
      aspect: this.props.params.ratiow / this.props.params.ratioh,
    },
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onFileUpload = image => {


    if (this.state.src == null) {
      alert('Please select the image to upload')
      return;
    } else {


      this.setState({ disabled: true});
      this.refs.btn.setAttribute("disabled", "disabled");


      const { blobFile } = this.state;
      const newImage = new File([blobFile], blobFile.name, { type: blobFile.type });
      let fd = new FormData();
      fd.append(newImage.name, newImage);
      //console.log(newImage);
      

      const formData = new FormData();
      formData.append('retailpacx', newImage);
      formData.append('foldername', sessionStorage.getItem('companybrandname'))


      const axconfig = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      axios.post(config.get('backendURL') + 'homelayout/upload/' + this.props.params.itemid, formData, axconfig)
        .then(res => {

          this.props.sendData(res, this.props.params.indexid);
       
          Modal.success({
            title: 'File Upload success',
            content: 'Please close this window and continue',
          });
        }
        );
    }
  };
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);

  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          //console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        //console.log(blob.name)
        //console.log(this)
        resolve(this.fileUrl);
        this.setState({ blobFile: blob }) // Set blob image inside the state here 
      }, 'image/png');
    });

  }


  render() {
    const { crop, croppedImageUrl, src } = this.state;
    return (
      <div className="App">
        <div>
          <input type="file" accept="image/*" onChange={this.onSelectFile} />
          <Button type="primary" htmlType="submit" ref="btn" onClick={this.onFileUpload.bind(this)}>
          {this.state.disabled ? 'Please wait...' : 'Submit'}
        </Button>
        </div>
        {src && (
          <ReactCrop
            src={src}
            crop={crop}
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        )}
        {croppedImageUrl && (
          <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
        )}
      </div>
    );
  }
}
export default Application;
