import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Layout, Menu, Breadcrumb } from 'antd';
import Header from "./header";
import Footer from "./footer";
import HomePage from "./home";
import Pages from "./pages";
import Articles from "./articles";
import ArticlesList from "./articles-list";
import NewArticle from "./new-article";
import NewCategory from "./new-category";
import Category  from "./category";
import Site from "./siteselect";
import NewPage from "./new-page";
import UploadImage from "./imageupload";
import Preview from "./preview";
import Landing from "./dashboard"; 
import Login from "./login";
import 'antd/dist/antd.css';


class Application extends React.Component {
    render() {

      
        
        return (

            <React.Fragment>
                <Router>
                   
                {(() => {
 
  switch (window.location.pathname) {
     case '/':
         return (
           <>
          
          </>
         )
     default:
         return (<>
          <Header />
          </>
         )
  }

})()}

                    

                    <Layout style={{ minHeight: '100vh' }}>
                  
                       
                            <Route exact path="/" component={Login} />
                            <Route exact path="/welcome" component={Landing} />
                            <Route exact path="/home" component={HomePage} />
                            <Route exact path="/sites/:id" component={Site} />
                            <Route exact path="/pages/:id" component={Pages} />
                            <Route exact path="/articleslist" component={ArticlesList} />
                            <Route exact path="/articles/:id" component={Articles} />
                            <Route exact path="/category/:id" component={Category} />
                            <Route exact path="/new/article" component={NewArticle} />
                            <Route exact path="/new/category" component={NewCategory} />
                            <Route exact path="/new/page/:id" component={NewPage} />
                            <Route exact path="/upload" component={UploadImage} />
                            <Route exact path="/preview/:id" component={Preview} />
                        
                    </Layout>
                    <Footer />
                </Router>

            </React.Fragment>

        );
    }
}

export default Application;