import React from 'react';
import './App.css';
import axios from 'axios';
import { Collapse, Modal, Drawer } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { Form, Input, InputNumber, Button } from 'antd';
import UploadItems from "./imageupload-global";
import config from 'react-global-configuration';
import "./config.js";
import jwt from 'jsonwebtoken';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Panel } = Collapse;

class App extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = { carouselitems: JSON.parse(this.props.params.MenuItemData), uploadedimage:'', id:'', disabled: false };
    this.getData = this.getData.bind(this);
  }
  componentDidMount() {
    this.setState({ carouselitems: this.state.carouselitems })
  }

  onFill = (heading, description, imageurl, id) => {
    this.setState({
      id: id
    });
    this.formRef.current.setFieldsValue({
      id: id,
    });
  };


  onClose = () => {
    this.setState({
      Logo: sessionStorage.getItem('uploadedimage'),
      visible: false,
    });
  };
  getData(val, indexid) {
    this.setState({
      uploadedimage:  val.data.imageName,
      visible: false,
    });
 
  }
  showDrawer = (id, itemheading, itemdata) => {
    this.setState({
      visible: true
    });
  };
  
  render() {
    const { visible, uploadedimage,id } = this.state;
    const onFinish = values => {

      this.setState({ disabled: true});
      this.refs.btn.setAttribute("disabled", "disabled");

      if (values.heading !== undefined) {
        this.state.carouselitems.Carousel[values.id]["heading"] = values.heading;
      }
      if (values.description !== undefined) {
        this.state.carouselitems.Carousel[values.id]["desc"] = values.description;
      }
      if (this.state.uploadedimage) {
        this.state.carouselitems.Carousel[this.state.id]["banner"] = this.state.uploadedimage;
      }

      console.log(this.state.carouselitems)

      console.log(values)
      console.log(this.state.uploadedimage)


      const pageData = {
        itemheading: 'Carousel',
        item: 'Carousel',
        width: this.props.params.w,
        height: this.props.params.h,
        x: this.props.params.x,
        y: this.props.params.y,
        order: '2',
        type: 'carousel',
        pageid:this.props.params.pageid,
        itemdata: JSON.stringify(this.state.carouselitems),
        companyid: sessionStorage.getItem('company')
      }
      const timestamp = Math.floor(Date.now() / 1000) + 1000;
      var payload = {
        "aud": process.env.REACT_APP_jwtAud,
        "exp": timestamp,
        "iss": process.env.REACT_APP_jwtIss
      };
   
      var token = jwt.sign(payload, process.env.REACT_APP_secret);
   
      const requestOptions = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer `+token,
        }  };

      axios.post(config.get('backendURL') +'homelayout/update/' + this.props.params.itemid, pageData, requestOptions)
        .then(
          function (data) {

            Modal.success({
              title: 'Success',
              content: 'Carousel details has been updated',
              onOk() { 
                window.location.reload(false);
              },
            });
    
    
          }

        );

    };

    return (
      <div className="container">
        <Collapse accordion>
          {
            this.state.carouselitems.Carousel.map((carousel, index) => (

              <Panel header={carousel.heading} key={index}>

                <div className="input-group mb-3">

                  <Row className="Full">
                    <Col span={12}>


                      <Form {...layout} ref={this.formRef} name="nest-messages" onFinish={onFinish} >
                        <Form.Item name={['heading']} label="Name" >
                          <Input defaultValue={carousel.heading} />
                        </Form.Item>
                        <Form.Item name={['description']} label="Description" >
                          <Input defaultValue={carousel.desc} />
                        </Form.Item>
                        <Form.Item name={['id']} label="ID" >
                          <Input name="id" defaultValue={index} />
                        </Form.Item>
                        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                          <Button type="primary" htmlType="submit" ref="btn" onClick={this.onFill.bind(this, carousel.heading, carousel.desc, carousel.banner, index)}>
                             {this.state.disabled ? 'Please wait...' : 'Submit'}
                          </Button>
                        </Form.Item>
                      </Form>

                    </Col>
                    <Col span={12}>

                      <Button variant="primary" onClick={this.showDrawer.bind(this)} type="button">
                        Upload Images
                      </Button>
                      <Drawer
                        title={this.state.itemheading}
                        placement="top"
                        closable={true}
                        height={720}
                        onClose={this.onClose}
                        visible={visible}
                        key="top"
                      >

                        <UploadItems sendData={this.getData} params={{ indexid: index, itemid: this.state.itemid, MenuItemId: this.state.itemheading, MenuItemData: this.state.itemdata, ratiow: this.props.params.w, ratioh: this.props.params.h, width: this.props.params.w, order: 0 }} />
                      </Drawer>
                    </Col>
                  </Row>
                </div>

              </Panel>
            ))}

        </Collapse>
      </div>
    )
  }
}

export default App;
