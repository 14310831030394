import React, { Component } from 'react';
import {  Row, Col} from 'react-bootstrap';
import { Divider, Modal, Form, Input, Button, Checkbox } from 'antd';
import {
    LockOutlined, UserOutlined
  } from '@ant-design/icons';

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { sites: [] };
  }
  onCompanySelect = () => {
    window.location.reload(); 
};
  componentDidMount() {

 
  }

 

  render() {
 

    const onFinish = values => {

        this.setState({ disabled: true });
  
        this.refs.btn.setAttribute("disabled", "disabled");
  
       
  console.log(values)
  let mockedUsername = "superadmin";
  let mockedPassword = "@er123111Kk";

  if (
    values &&
    values.username === mockedUsername &&
    values.password === mockedPassword
  ) {
    sessionStorage.setItem('loggedin', "true");
    sessionStorage.setItem('mockedUsername', mockedUsername);
    window.location.href = "/welcome" 
   

  }else {
    Modal.error({
        title: 'Invalid Login',
        content: 'Invalid username or password',
        onOk() { window.location.href = "/" },
      });

    }
 
        
  
      };

    return (
        <Row>
            <Col sm={12}>&nbsp;
                </Col>
            <Col sm={4}>
                </Col>
                <Col sm={4}>
              
            <div className="fade alert alert-light show">



<Row className="justify-content-md-center">

  <Col sm={12}>



    <Divider orientation="left"><h5>RETAILPACX LOG IN</h5></Divider>
    <Form

      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
      </Form.Item>

      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <a className="login-form-forgot" href="" className="float-right">
          Forgot password
</a>
      </Form.Item>

      <Form.Item
        name="messagebox"
        label="Please wait"
        hasFeedback
        style={{ display: 'none' }}
        validateStatus="validating"
        help="The information is being validated..."
      >

      </Form.Item>


      <Form.Item  >
        <Button type="primary" htmlType="submit" ref="btn" block danger >
          {this.state.disabled ? 'Please wait...' : 'Login'}
        </Button>
      </Form.Item>
    </Form>
     </Col>
</Row>





</div>

                
            </Col>
            <Col sm={4}>
                </Col>
        </Row>

    );
  }
}
 