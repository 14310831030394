import React from 'react';
import { Button, Form, Layout, Breadcrumb, Input, Modal } from 'antd';
import axios from 'axios';
import config from 'react-global-configuration';
const { TextArea } = Input;

const { Content } = Layout;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
class Application extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pages: [], pcontent: '' };
    }
    componentDidMount() {
        document.title = "RetailPacx - Category"
        this.handleInputOnChange = this.handleInputOnChange.bind(this);
        this.setState({
            status: true,
            visibility: true,
            companyid: sessionStorage.getItem('company')
        })
    }


    handleInputOnChange = (event) => {
        this.forceUpdate();
        this.setState({
            slug: event.target.value
        })
        this.forceUpdate();
    }

    render() {
        const pageSubmit = values => {
            const categoryData = {
                category: values.name,
                slug: values.slug.replace(/ /g, '-').toLowerCase(),
                description: values.description,
                status: this.state.status,
                visibility: this.state.visibility,
                companyid: this.state.companyid
            }
            console.log(values)
            
            axios.post(config.get('backendURL') + 'category/add', categoryData)
                .then(res => console.log(res.data));

            Modal.success({
                title: 'Success',
                content: 'Page details has been added',
                onOk() {
                    window.location = '/articleslist'
                },
            });
        }

        return (
            <Content style={{ margin: '0 16px' }}>

                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Category</Breadcrumb.Item>
                    <Breadcrumb.Item>Add New Category</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Form
                        {...layout}
                        onFinish={pageSubmit}
                    >
                        <Form.Item label="Category Name" rules={[{ required: true }]}>
                            <Form.Item name="name" noStyle><Input onChange={e => this.setState({ slug: e.target.value })} /></Form.Item><span>The name is how it appears on your site.</span>
                        </Form.Item>
                        <Form.Item label="Slug" rules={[{ required: true }]}>
                            <Form.Item name="slug" noStyle><Input defaultValue={this.state.slug} /></Form.Item><span>The “slug” is the URL-friendly version of the name. It is usually all lowercase and contains only letters, numbers, and hyphens.</span>
                        </Form.Item>
                        <Form.Item label="Description" rules={[{ required: false }]}>
                            <Form.Item name="description" noStyle><Input defaultValue={this.state.description} /></Form.Item><span>The description is not prominent by default; however, some themes may show it.</span>
                        </Form.Item>

                        <Form.Item  >
                            <Button type="primary" htmlType="submit" >Submit</Button>
                        </Form.Item>
                    </Form>
                </div>


            </Content>
        )
    }
}

export default Application;