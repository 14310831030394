import React from 'react';
import { Button, Form, Layout, Breadcrumb, Modal, Input } from 'antd';
import axios from 'axios';
import LeftMenu from "./leftmenu";
import config from 'react-global-configuration';
import * as _ from 'lodash';
const { TextArea } = Input;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const { Content } = Layout;

class Application extends React.Component {

  static defaultProps = {
    className: "layout",
    cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 2 },
    rowHeight: 20
  };


  constructor(props) {
    super(props);
    const datax = [], datay = [];
    const loading = true;
    this.onChangeCategory = this.onChangeCategory.bind(this);
    this.onChangeSlug = this.onChangeSlug.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.state = {
      pageorder: '', id: '', pages: [], datax, slug: '', category: '', description: '', layout: [], widgets: [],
      items: [],
      newCounter: 0
    };
  }

  componentDidMount() {
    document.title = "RetailPacx - Category"

    axios.get(config.get('backendURL') + 'category/' + this.props.match.params.id + '/' + sessionStorage.getItem('company'))
      .then(response => {
        this.setState({
          category: response.data.category,
          slug: response.data.slug,
          description: response.data.description,
          companyid: response.data.companyid,
          visibility: response.data.visibility,
          status: response.data.status,
          id: response.data._id,
          loading: true,
        })
      })
      .catch((error) => {
        console.log(error);
      })
  }

  onChangeCategory(e) {
    this.setState({
      category: e.target.value
    })
  }

  onChangeSlug(e) {
    this.setState({
      slug: e.target.value
    })
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value
    })
  }
  render() {
    const pageSubmit = values => {
      const categoryData = {
        category: values.name,
        slug: values.slug.replace(/ /g, '-').toLowerCase(),
        description: values.description,
        status: this.state.status,
        visibility: this.state.visibility,
        companyid: this.state.companyid
      }
      //  console.log(this.state.pcontent)



      axios.post(config.get('backendURL') + 'category/update/' + this.state.id, categoryData)
        .then(res => console.log(res.data));

      Modal.success({
        title: 'Success',
        content: 'Category details has been updated',
        onOk() {
          window.location = '/articleslist'
        },
      });
    }

    return (

      <>
        <LeftMenu />
        <Layout className="site-layout">

          <Content style={{ margin: '0 16px' }}>

            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Category</Breadcrumb.Item>
              <Breadcrumb.Item>Edit Category</Breadcrumb.Item>
            </Breadcrumb>

            <h1>Category</h1>

{
  this.state.loading ? (
            <Form
              name="register"
              {...layout}
              onFinish={pageSubmit}
            >

              <Form.Item name="name" initialValue={this.state.category} label="Category Name" rules={[{ required: true }]}>
                <Input/>
              </Form.Item>
              <Form.Item name="slug" initialValue={this.state.slug}  label="Slug" rules={[{ required: true }]}>
                <Input onChange={this.onChangeSlug} />
              </Form.Item>
              <Form.Item name="description"  label="Description" initialValue={this.state.description} rules={[{ required: false }]}>
                <Input
                  placeholder=""  onChange={this.onChangeDescription}
                  autoSize={{ minRows: 2, maxRows: 6 }}
                /></Form.Item>

              <Form.Item  >
                <Button type="primary" htmlType="submit" >Submit</Button>
              </Form.Item>
            </Form>)

:('Please wait.. Loading..')
  }



          </Content>
        </Layout>
      </>

    )
  }
}

export default Application;