import React, { Component } from 'react';
import { Modal, Layout, Input, Menu, Button, Select, Popover } from 'antd';
import ReactDragListView from 'react-drag-listview/lib/index.js';
import axios from 'axios';
import { DragOutlined, SettingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import config from 'react-global-configuration';

const { Header, Sider } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;
const { confirm } = Modal;
export default class header extends Component {
  constructor(props) {
    super(props);

    const datax = [];
    this.state = {
      datax, menus: []
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };


  componentDidMount() {

    axios.get(config.get('backendURL') + 'pages/byid/' + this.props.params.MenuItemId)
      .then(response => {
        this.setState({ datax: response.data, comid: this.props.params.MenuItemId })
      })
      .catch((error) => {
        console.log(error);
      })
  }

  handleOk = (id) => {
    this.setState({ loading: true });
    


      if(!document.getElementById("clicked"+id).value){
        Modal.error({
          title: 'Error',
          content: 'Please enter a value',
          onOk() {
          },
        })
      }else{
    
        var updatepage = {
          pagetitle: document.getElementById("clicked"+id).value,
          content: this.state.content,
          companyid: sessionStorage.getItem('company'),
          featuredimage: this.state.featuredimage,
          slug: this.state.slug,
          author: this.state.author,
          status: this.state.status,
          visibility: this.state.visibility,
          publishon: this.state.publishon,
          pageorder: this.state.order,
          menus:  this.state.menus
        }
        console.log(updatepage)
        axios.post(config.get('backendURL') + 'pages/update/' + id, updatepage)
        .then(function (data) {
    
          Modal.success({
            title: 'Success',
            content: 'Page renamed',
            onOk() { 
              window.location.href = '/home';
            },
          });
    
    
        })
     
      }
     
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onPopClick = (id,content, featuredimage, slug,author, status, visibility, publishon, menus,order ) => {
    this.setState({ clickedid: id, content:content, featuredimage:featuredimage, slug:slug,author:author, status:status, visibility:visibility, publishon:publishon, menus:menus, pageorder:order });
  };

  onPopClickDelete = (id) => {
    this.setState({ clickedid: id });


    confirm({
      title: 'Are you sure delete this page?',
      icon: <ExclamationCircleOutlined />,
      content: 'You can not restore this item once you delete it.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        axios.delete(config.get('backendURL') + 'pages/' + id )
          .then(response => {
            Modal.success({
              title: 'Success',
              content: 'Page has been deleted',
              onOk() { 
                window.location.href = '/home';
              },
            });

           
          })
          .catch((error) => {
            console.log(error);
          })
      },
      onCancel() {
        console.log('Cancel');
      },
    });



  };

  onPopClickAdd = (id) => {
    window.location.href = "/new/page/" + id
    console.log(id)
  };

  //href="/new/page"


  render() {
    const { visible, loading, clickedid } = this.state;


    if (this.props.params.MenuItemId != this.state.comid) {
      this.componentDidMount();
    }


    const content = (
      <div>
        <p><a href='#' onClick={this.showModal}>Rename</a>

          <Modal
            visible={visible}
            title="Rename Page"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>
                Return
            </Button>,
              <Button key="submit" type="primary" loading={loading} onClick={this.handleOk.bind(this, this.state.clickedid, )}>
                Submit
            </Button>,
            ]}
          >
             <Input id={'clicked'+this.state.clickedid} placeholder="Rename" />
         Renaming this page does not change the URL. To change it, go to Page URL.
        </Modal>
        </p>
        <p><a href='#' onClick={this.onPopClickDelete.bind(this, this.state.clickedid)}>Delete</a></p>
      </div>


    );

    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const datax = [...that.state.datax];
        const item = datax.splice(fromIndex, 1)[0];
        datax.splice(toIndex, 0, item);
        that.setState({ datax });



        var fullUserDetails = [];

        var updatepage = datax.map((item, index) => {
          
          var updatevpage = {
            pagetitle:item.pagetitle,
            content: item.content,
            companyid: item.companyid,
            featuredimage: item.featuredimage,
            slug: item.slug,
            author: item.author,
            status: item.status,
            visibility: item.visibility,
            publishon: item.publishon,
            pageorder: index,
            menus:  item.menus
          }
          axios.post(config.get('backendURL') + 'pages/update/' + item._id, updatevpage)
          .then(response => {
         
            Modal.success({
              title: 'Success',
              content: 'Page order has been changed',
              onOk() { 
                window.location.href = '/home';
              },
            });
      
      
          })
        }
       
          
        
        )

         



      },
      nodeSelector: 'li',
      handleSelector: 'a'
    };
    return (
      <ReactDragListView {...dragProps}>

        <div className="ant-list ant-list-split"></div>
        <div className="ant-spin-container">
          <ul className="ant-list-items">
            {this.state.datax.map((item, index) => (
              <li key={index} className="ant-list-item">
                <div >
                  <div className="ant-list-item-meta-avatar"> <a href="#"><DragOutlined style={{ fontSize: '15px' }} /></a></div>
                </div>
                <div className="ant-list-item-meta-content"><div className="ant-list-item-meta-description"><a href={"/pages/" + item.slug}>{item.pagetitle}</a></div></div>
                <div>
                  <Popover placement="right" title={item.pagetitle} content={content} trigger="focus" onClick={this.onPopClick.bind(this, item._id, item.content, item.featuredimage, item.slug,item.author, item.status, item.visibility, item.publishon, item.menus, item.pageorder)}>
                    <SettingOutlined />
                  </Popover>


                </div>
                <hr />

              </li>
            ))}
          </ul>

          <div className="float-right"> <Button type="primary" onClick={this.onPopClickAdd.bind(this, this.props.params.MenuItemId)}>Add New</Button>
          </div>
        </div>
      </ReactDragListView >


    );
  }
}