import React from 'react';
import { Row, Col} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faShoppingCart} from '@fortawesome/free-solid-svg-icons'
import * as NumericInput from "react-numeric-input";


class App extends React.Component {

  constructor(props) {
      super(props);
  }
 
  render() {
      return (
        <Row>
          <Col sm={12}>&nbsp; </Col>
      <Col sm={12}><h2>{this.props.params.ItemHeading.toUpperCase()}</h2></Col>
      <Col sm={6} xs={12} md={2} lg={2}>
      <img src="https://cdn.neurolage.com/retailpacx/shankarcolimited/retailpacx-1615452851534.jpeg" alt="." style={{ width: '95%' }}  />

        </Col>

        <Col sm={6} xs={12} md={2} lg={2}>
        <img src="https://cdn.neurolage.com/retailpacx/shankarcolimited/retailpacx-1615452851534.jpeg" alt="." style={{ width: '95%' }}  />

        </Col>
        <Col sm={6} xs={12} md={2} lg={2}>
        <img src="https://cdn.neurolage.com/retailpacx/shankarcolimited/retailpacx-1615452851534.jpeg" alt="." style={{ width: '95%' }}  />
        </Col>
        <Col sm={6} xs={12} md={2} lg={2}>
        <img src="https://cdn.neurolage.com/retailpacx/shankarcolimited/retailpacx-1615452851534.jpeg" alt="." style={{ width: '95%' }}  />

        </Col>
        <Col sm={6} xs={12} md={2} lg={2}>
        <img src="https://cdn.neurolage.com/retailpacx/shankarcolimited/retailpacx-1615452851534.jpeg" alt="." style={{ width: '95%' }}  />

        </Col>
        <Col sm={6} xs={12} md={2} lg={2}>
        <img src="https://cdn.neurolage.com/retailpacx/shankarcolimited/retailpacx-1615452851534.jpeg" alt="." style={{ width: '95%' }}  />
        </Col>
      </Row>
      )
  }
}

export default App;
