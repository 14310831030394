import React from 'react';
import { Button, Form, Layout, Breadcrumb, Input, Modal } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import config from 'react-global-configuration';

const { Content } = Layout;

class Application extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pages: [], pcontent : '' };
    }
    componentDidMount() {
        document.title = "RetailPacx - Site Editor"


        this.setState({
            pcontent: 'sample',
            featuredimage: 'featuredimage.jpg',
            author: sessionStorage.getItem('mockedUsername'),
            status: true,
            visibility: true,
            publishon: '',
            menus: '',
            companyid: sessionStorage.getItem('company')
        })

    }

    
    handleEditorChange = (content, editor) => {
        
        this.setState({
            pcontent: content
        })
    }
    
    render() {

       const pageSubmit = values => {
 
            const pageData = {
                pagetitle: values.name,
                content: this.state.pcontent,
                featuredimage: this.state.featuredimage,
                slug: values.name.replace(/ /g, '-').toLowerCase(),
                author: this.state.author,
                status: this.state.status,
                visibility: this.state.visibility,
                publishon: this.state.publishon,
                menus: this.props.match.params.id,
                companyid: this.state.companyid
            }
        //  console.log(this.state.pcontent)
  
                axios.post(config.get('backendURL') + 'pages/add', pageData)
                .then(res => console.log(res.data));

                Modal.success({
                    title: 'Success',
                    content: 'Page details has been added',
                    onOk() {
                        window.location='/pages/'+values.name.replace(/ /g, '-').toLowerCase()
                     },
                  });
        }

        return (
            <Content style={{ margin: '0 16px' }}>

                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Pages</Breadcrumb.Item>
                    <Breadcrumb.Item>{this.state.pagetitle}</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <Form
 onFinish={pageSubmit}
>
                    <Form.Item name="name" label="Page Heading" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                     

<Form.Item  >
<Button type="primary" htmlType="submit" >Submit</Button>
</Form.Item>
  </Form>
                </div>


            </Content>
        )
    }
}

export default Application;