import React from 'react';
import axios from 'axios';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import config from 'react-global-configuration';

class App extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = { vimage: [], loaded: false };
  }
  componentDidMount() { 
    this._isMounted = true;
    //console.log(this.props.params.ItemData)
    axios.get(config.get('backendURL') + 'homelayout/image/' + this.props.params.ItemData)
    .then(response => {
      //console.log((response.data)[0].img)
      this.setState({
        vtype: (response.data)[0].img.contentType,
        destination: (response.data)[0].meta_data.destination,
        imageName: (response.data)[0].meta_data.filename,
        vimage: (response.data)[0].img.data.toString()
      })
    })
    .catch(function (error) {
      console.log(error);
    })

};

componentWillUnmount() {
  this._isMounted = false;
}
 
  render() {
 //console.log(this.state.vimage)
   
      return (
        <>
  

  {
    this.state.destination=='images' ? (
<img  src={'https://cdn.neurolage.com/retailpacx/'+sessionStorage.getItem('companybrandname')+'/'+this.state.imageName}  alt={this.props.params.ItemHeading} onLoad={() => this.setState({loaded: true})} style={ { width: '100%', height: 'auto', 'max-height':'100%', 'max-width':'100%'}} class="contain" />
 ):(
<video autostart autoPlay loop muted="muted"  src={'https://cdn.neurolage.com/retailpacx/'+sessionStorage.getItem('companybrandname')+'/'+this.state.imageName} style={{width: '100%'}}></video> 
 )
  }
 
 </>
      )
  }
}

export default App;
