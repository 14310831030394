import React, { } from "react";
import { Upload, Modal, message, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import config from 'react-global-configuration';
import axios from 'axios';
import jwt from 'jsonwebtoken';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }

    return isJpgOrPng && isLt2M;
}

 

class PicturesWall extends React.Component {

  constructor(props) {
    super(props);
    
  }
  state = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: []
  };

  componentDidMount() {

    const ItemData = this.props.params.ItemData.split(',')

    let photos =[]

    ItemData.map((img, index) => {
      let dataArr = [];
      dataArr["uid"] = index
      dataArr["status"] = 'done' 
      dataArr["name"] = img.split(':')[0]
      dataArr["ratio"] = img.split(':')[1]
      dataArr["url"] = 'https://cdn.neurolage.com/retailpacx/' + sessionStorage.getItem('companybrandname') + '/' +img.split(':')[0]
   
      photos.push(dataArr)
      
    })

    this.setState({ fileList: photos })

  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });
  
  handleSubmit = event => {
    event.preventDefault();
 
    console.log(this.state.fileList)
    
    
    let fulllist=''
    this.state.fileList.map((file, index) => {
      let fdata = new FormData();
       if(file.status=='done'){
        fulllist = fulllist + ',' + file.name + ':' + file.ratio


           
        const pageData = {
          itemheading:  'gallery',
      item:this.props.params.MenuItemId,
      width: this.props.params.w,
      height: this.props.params.h,
      x: this.props.params.x,
      y: this.props.params.y,
      order: this.props.params.order,
      type: 'gallery',
      itemdata: fulllist.substring(1),
      link: '',
      pageid:this.props.params.pageid,
      companyid: sessionStorage.getItem('company')
 
        }
  
        const timestamp = Math.floor(Date.now() / 1000) + 1000;
        var payload = {
          "aud": process.env.REACT_APP_jwtAud,
          "exp": timestamp,
          "iss": process.env.REACT_APP_jwtIss
        };
  
        var token = jwt.sign(payload, process.env.REACT_APP_secret);
  
        const requestOptions = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ` + token,
          }
        };
  
   
        axios.post(config.get('backendURL') + 'homelayout/update/' + this.props.params.itemid, pageData, requestOptions)
          .then(function (data) {
  
            Modal.success({
              title: 'Succes',
              content: 'Gallery updated',
              onOk() {
                window.location.reload(false);
              },
            });
  
      }
      )
        .catch(err => {
          console.log("err", err);
        });
       }else{
        fdata.append('retailpacx', this.state.fileList[index].originFileObj);
        fdata.append('fileURL',  'https://cdn.neurolage.com/retailpacx/' + sessionStorage.getItem('companybrandname') + '/' );
        fdata.append('foldername', sessionStorage.getItem('companybrandname'))
        const axconfig = {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }

        axios.post(config.get('backendURL') + 'homelayout/gallery' , fdata, axconfig)
        .then(res => {
          fulllist = fulllist + ',' + res.data.imageName + ':' + res.data.imgwidth +'x'+res.data.imgheight
   
           
          const pageData = {
            itemheading:  'gallery',
        item:this.props.params.MenuItemId,
        width: this.props.params.w,
        height: this.props.params.h,
        x: this.props.params.x,
        y: this.props.params.y,
        order: this.props.params.order,
        type: 'gallery',
        itemdata: fulllist.substring(1),
        link: '',
        pageid:this.props.params.pageid,
        companyid: sessionStorage.getItem('company')
   
          }
    
          const timestamp = Math.floor(Date.now() / 1000) + 1000;
          var payload = {
            "aud": process.env.REACT_APP_jwtAud,
            "exp": timestamp,
            "iss": process.env.REACT_APP_jwtIss
          };
    
          var token = jwt.sign(payload, process.env.REACT_APP_secret);
    
          const requestOptions = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ` + token,
            }
          };
    
     
          axios.post(config.get('backendURL') + 'homelayout/update/' + this.props.params.itemid, pageData, requestOptions)
            .then(function (data) {
    
              Modal.success({
                title: 'Succes',
                content: 'Gallery updated',
                onOk() {
                  window.location.reload(false);
                },
              });
    
        }
        )
          .catch(err => {
            console.log("err", err);
          });
            
        }) 

       }
       console.log(fulllist)
    })

  
   
  };
  /*
          console.log(data)
          if (data.statusText == 'OK') {

            Modal.success({
              title: 'Succes',
              content: 'Image has been uploaded successfully',
              onOk() {
                window.location.reload(false);
              },
            });


          }
        })
 
*/
  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    
    return (
      <>
        <Upload
 
          accept="image/png, image/jpeg"
          listType="picture-card"
          fileList={fileList}
          beforeUpload={beforeUpload}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          beforeUpload={() => false}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>

        <Button onClick={this.handleSubmit} // this button click will trigger the manual upload
        >
            Submit
        </Button>

        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </>
    );
  }
}

export default PicturesWall;