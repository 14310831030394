import React, { } from "react";
import { Upload, Modal, Tag, Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import config from 'react-global-configuration';
import axios from 'axios';
import jwt from 'jsonwebtoken';
const { CheckableTag } = Tag;
 
class PicturesWall extends React.Component {

  constructor(props) {
    super(props);
    
  }
  state = {
    datax:[], selectedTags: this.props.params.ItemData.split(',')
  };

  handleChange = (tag, checked) => {
    const { selectedTags } = this.state;
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
    console.log('You are interested in: ', nextSelectedTags);
    this.setState({ selectedTags: nextSelectedTags });
}
  componentDidMount() {

    axios.get(config.get('backendURL') + 'category/' + sessionStorage.getItem('company'))
    .then(response => {
        //console.log(response)
        this.setState({ datax: response.data })
    })
    .catch((error) => {
        console.log(error);
    })
 

  }
 
  handleSubmit = event => {
    event.preventDefault();

        const pageData = {
        itemheading:'blog',
        item:'ITEM',
        width: this.props.params.w,
        height: this.props.params.h,
        x: this.props.params.x,
        y: this.props.params.y,
        order: 0,
        type: 'blog',
        itemdata: this.state.selectedTags+"",
        companyid: sessionStorage.getItem('company')
          }
    
          const timestamp = Math.floor(Date.now() / 1000) + 1000;
          var payload = {
            "aud": process.env.REACT_APP_jwtAud,
            "exp": timestamp,
            "iss": process.env.REACT_APP_jwtIss
          };
    
          var token = jwt.sign(payload, process.env.REACT_APP_secret);
    
          const requestOptions = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ` + token,
            }
          };
    console.log(pageData)
     
          axios.post(config.get('backendURL') + 'homelayout/update/' + this.props.params.id, pageData, requestOptions)
            .then(function (data) {
    
              Modal.success({
                title: 'Succes',
                content: 'Blogs category updated',
                onOk() {
                  window.location.reload(false);
                },
              });
    
        }
        )
          .catch(err => {
            console.log("err", err);
          });
        
      
   
  };
   
  render() {
 
 const { selectedTags } = this.state;
    
    return (
      <>
         
         <Form.Item name="categories" label="Categories" >

{this.state.datax.map(tag => (
    <CheckableTag
        key={tag._id}
        checked={selectedTags.indexOf(tag._id) > -1}
        onChange={checked => this.handleChange(tag._id, checked)}
    >
        {tag.category}
    </CheckableTag>
))} 
(Please click on a category to add)
</Form.Item>
        <Button onClick={this.handleSubmit} // this button click will trigger the manual upload
        >
            Submit
        </Button>

      
      </>
    );
  }
}

export default PicturesWall;