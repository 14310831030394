import React from 'react';
import axios from 'axios';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import config from 'react-global-configuration';

class App extends React.Component {
  _isMounted = false;

  constructor(props) {
      super(props);
      this.state = { vimage: [], loaded: false };
  }
  componentDidMount() { 
    this._isMounted = true;
    axios.get(config.get('backendURL') + 'homelayout/image/' + this.props.params.ItemData)
    .then(response => {
      //console.log((response.data)[0].img)
      this.setState({
        vtype: (response.data)[0].img.contentType,
        imageName: (response.data)[0].meta_data.filename,
        vimage: (response.data)[0].img.data.toString()
      })
    })
    .catch(function (error) {
      console.log(error);
    })

};

componentWillUnmount() {
  this._isMounted = false;
}
 
  render() {
 //console.log(this.state.vimage)
   
      return (
      
        <div className={this.props.params.className}>
{this.state.loaded ? null :
          <div className="divLoader">
          <LoadingOutlined  style={{ fontSize: '56px', color: '#08c' }} /><br/>
           </div>
        }
<img className="w-100" src={'https://cdn.neurolage.com/retailpacx/'+sessionStorage.getItem('companybrandname')+'/'+this.state.imageName}  alt={this.props.params.ItemHeading} onLoad={() => this.setState({loaded: true})} style={this.state.loaded ? {} : {display: 'none'}} />
  </div>
      )
  }
}

export default App;
