import React, { useState } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Photo from "./Photo";
import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

const photos = [];
/* popout the browser and maximize to see more rows! -> */
const SortablePhoto = SortableElement(item => <Photo {...item} />);
const SortableGallery = SortableContainer(({ items }) => (
  <Gallery photos={items} renderImage={props => <SortablePhoto {...props} />} />
));



function App(props) {


  const ItemData = props.params.ItemData.split(',')

  let photos =[]
  
  ItemData.map((img, index) => {
    let dataArr = [];
    dataArr["src"] = 'https://cdn.neurolage.com/retailpacx/' + sessionStorage.getItem('companybrandname') + '/' +img.split(':')[0]
    dataArr["width"] = img.split(':')[1].split("x")[0]
    dataArr["height"] = img.split(':')[1].split("x")[1]

    photos.push(dataArr)
  })

 console.log(photos)
 // const photos = dataArr
  const [items, setItems] = useState(photos);
 
  return (
    <div>
      <SortableGallery items={items}  axis={"xy"} />
    </div>
  );
}



export default App;
