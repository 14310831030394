import React, { Component } from 'react'
import ReactDevicePreview from 'react-device-preview'
import { Row, Col} from 'react-bootstrap';
import axios from 'axios';
import LayoutInclude from "./layout-include";
import config from 'react-global-configuration';

export default class Company extends Component {

  constructor(props) {
    super(props);

    const datax = [], datay = [];
    this.state = {
      datax, datay, menus: []
    };
  } 
  
  componentDidMount() {

    axios.get(config.get('backendURL') + 'layouts/layout/'+sessionStorage.getItem('company'))
    .then(response => {
      this.setState({ datax: JSON.parse(response.data[0].itemdetails).sort((a, b) => (a.order - b.order)), slayoutid:response.data[0]._id})
    })
    .catch((error) => {
      console.log(error);
    })


   
  }
  render () {
    return (
      <div>
        
        <Row>
            <Col sm={12}>&nbsp;</Col>
            <Col sm={1}></Col>
           <Col sm={5}>
        

        <ReactDevicePreview
          device="macbookpro"
          scale="0.6"
          show="https://www.kli.org/"
          >

<div class="preview show-grid">
      <Row>
      <Col>
      Static Header 
        </Col>
      </Row>
        <Row>
        {  console.log(this.state.datax)}
        {this.state.datax ? (
           this.state.datax.map((layoutd, index) => (
            <Col sm={layoutd.width} xs={layoutd.width}>
               <LayoutInclude params={{ layoutid: layoutd.layoutid,width:layoutd.width  }} />
           </Col>
          ))
        ):(<></>)
          }
   
        </Row>
        </div>

        </ReactDevicePreview>
        </Col>
        <Col sm={3}>
      

        <ReactDevicePreview
          device="ipadmini"
          scale="0.6"
          >
         <div class="preview show-grid">
      <Row>
      <Col>
      Static Header 
        </Col>
      </Row>
        <Row>
        {  console.log(this.state.datax)}
        {this.state.datax ? (
           this.state.datax.map((layoutd, index) => (
            <Col sm={layoutd.width} xs={layoutd.width}>
               <LayoutInclude params={{ layoutid: layoutd.layoutid,width:layoutd.width  }} />
           </Col>
          ))
        ):(<></>)
          }
   
        </Row>
        </div>  </ReactDevicePreview>
        </Col>
        <Col sm={2}>
      
        <ReactDevicePreview
          device="iphonex"
          scale="0.6"
          >
         <div class="preview show-grid">
      <Row>
      <Col>
      Static Header 
        </Col>
      </Row>
        <Row>
        {  console.log(this.state.datax)}
        {this.state.datax ? (
           this.state.datax.map((layoutd, index) => (
            <Col sm={layoutd.width} xs={layoutd.width}>
               <LayoutInclude params={{ layoutid: layoutd.layoutid,width:layoutd.width  }} />
           </Col>
          ))
        ):(<></>)
          }
   
        </Row>
        </div>  </ReactDevicePreview>
        </Col>
        <Col sm={1}></Col>
       </Row>
      </div>
    )
  }
}