import React from 'react';
import './App.css';
import axios from 'axios';
import {Row, Col } from 'react-bootstrap';
import config from 'react-global-configuration';
import "./config.js";

class App extends React.Component {

  constructor(props) {
      super(props);
      this.state ={
          file: null
      };
      this.onFormSubmit = this.onFormSubmit.bind(this);
      this.onChange = this.onChange.bind(this);
  }
  onFormSubmit(e){
      e.preventDefault();
      const formData = new FormData();
      formData.append('foldername', sessionStorage.getItem('companybrandname'))
      formData.append('retailpacx',this.state.file);
      const config = {
          headers: {
              'content-type': 'multipart/form-data'
          }
      };
      axios.post(config.get('backendURL') + "upload",formData,config)
          .then((response) => {
              console.log(response)
              //alert("The file is successfully uploaded");
          }).catch((error) => {
      });
  }

  onChange(e) {
      this.setState({file:e.target.files});
  }

  render() {
      return (
        <div className="container">
       

          <Row>
            <Col>
               
         Pick items from the list to add
        </Col>
        </Row>

       
      </div>
      )
  }
}

export default App;
