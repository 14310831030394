import React, { Component } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import { Modal, Drawer } from 'antd';
import UploadItems from "./imageupload-global";
import config from 'react-global-configuration';
import jwt from 'jsonwebtoken';

export default class Company extends Component {
  constructor(props) {
    super(props);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeAddressLine1 = this.onChangeAddressLine1.bind(this);
    this.onChangeAddressLine2 = this.onChangeAddressLine2.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangeStateProvinceRegion = this.onChangeStateProvinceRegion.bind(this);
    this.onChangePostZipCode = this.onChangePostZipCode.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeFax = this.onChangeFax.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeOpenningHours = this.onChangeOpenningHours.bind(this);
    this.onChangeSubDomain = this.onChangeSubDomain.bind(this);
    this.onChangeCustomerDomain = this.onChangeCustomerDomain.bind(this);
    this.onChangeOrganisationID = this.onChangeOrganisationID.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      Name: '',
      Logo: '',
      favicon: '',
      AddressLine1: '',
      AddressLine2: '',
      City: '',
      StateProvinceRegion: '',
      PostZipCode: '',
      Country: '',
      Phone: '',
      Fax: '',
      Email: '',
      OpenningHours: '',
      SubDomain: '',
      CustomerDomain: '',
      OrganisationID: '',
      ServiceKey: '',
      ClientKey: '',
      LogoCDN: '',
      LocationID: '',
      WishListEnabled: false,
      HelpPage: '',
      SocialMedia: '',
      DeliveryMessage: '',
      ShopNowItems: '',
      theme: '',
      FBappid: '',
      FBpageid: '',
      CDNPath:''
    }
  }

  componentDidMount() {

  }
  showDrawer = (id, itemheading, itemdata) => {
    this.setState({
      visible: true
    });
  };
  showfaviconDrawer = (id, itemheading, itemdata) => {
    this.setState({
      faviconvisible: true
    });
  };
  onChangeName(e) {
    this.setState({
      Name: e.target.value
    })
  }
  onChangeAddressLine1(e) {
    this.setState({
      AddressLine1: e.target.value
    })
  }
  onChangeAddressLine2(e) {
    this.setState({
      AddressLine2: e.target.value
    })
  }
  getData(val, doid) {
    sessionStorage.setItem('uploadedimage', val.data.imageName)
  }
  getfaviconData(val, doid) {
    sessionStorage.setItem('faviconimage', val.data.imageName)
  }
  onChangeCity(e) {
    this.setState({
      City: e.target.value
    })
  }
  onChangeStateProvinceRegion(e) {
    this.setState({
      StateProvinceRegion: e.target.value
    })
  }
  onChangePostZipCode(e) {
    this.setState({
      PostZipCode: e.target.value
    })
  }
  onChangeCountry(e) {
    this.setState({
      Country: e.target.value
    })
  }
  onChangePhone(e) {
    this.setState({
      Phone: e.target.value
    })
  }
  onChangeFax(e) {
    this.setState({
      Fax: e.target.value
    })
  }
  onChangeEmail(e) {
    this.setState({
      Email: e.target.value
    })
  }
  onChangeOpenningHours(e) {
    this.setState({
      OpenningHours: e.target.value
    })
  }
  onChangeSubDomain(e) {
    this.setState({
      SubDomain: e.target.value
    })
  }
  onChangeCustomerDomain(e) {
    this.setState({
      CustomerDomain: e.target.value
    })
  }
  onChangeOrganisationID(e) {
    this.setState({
      OrganisationID: e.target.value
    })
  }

  onClose = () => {
    this.setState({
      Logo: sessionStorage.getItem('uploadedimage'),
      visible: false,
    });
  };
  onfaviconClose = () => {
    this.setState({
      favicon: sessionStorage.getItem('faviconimage'),
      faviconvisible: false,
    });
  };
  onSubmit(e) {
    e.preventDefault();

    const company = {
      Name: this.state.Name,
      Logo: sessionStorage.getItem('uploadedimage'),
      favicon: sessionStorage.getItem('faviconimage'),
      AddressLine1: this.state.AddressLine1,
      AddressLine2: this.state.AddressLine2,
      City: this.state.City,
      StateProvinceRegion: this.state.StateProvinceRegion,
      PostZipCode: this.state.PostZipCode,
      Country: this.state.Country,
      Phone: this.state.Phone,
      Fax: this.state.Fax,
      Email: this.state.Email,
      OpenningHours: this.state.OpenningHours,
      SubDomain: this.state.SubDomain,
      CustomerDomain: this.state.CustomerDomain,
      OrganisationID: this.state.OrganisationID,
      LogoCDN: this.state.LogoCDN,
      LocationID: this.state.LocationID,
      WorldPayServiceKey: this.state.WorldPayServiceKey,
      WorldPayClientKey: this.state.WorldPayClientKey,
      WishListEnabled: this.state.WishListEnabled,
      HelpPage: this.state.HelpPage,
      SocialMedia: this.state.SocialMedia,
      Theme: this.state.theme,
      DeliveryMessage: this.state.DeliveryMessage,
      ShopNowItems: this.state.ShopNowItems,
      FBappid: this.state.FBappid,
      FBpageid: this.state.FBpageid,
      CDNPath: this.state.Name.replace(/ /g, '').replace('&', '').replace(/(^\&)|,/g, '').toLowerCase()
    }

    if (!sessionStorage.getItem('uploadedimage')) {
      Modal.error({
        title: 'Error',
        content: 'Please upload a logo',
        onOk() { },
      });
      return;
    }

    if (!sessionStorage.getItem('faviconimage')) {
      Modal.error({
        title: 'Error',
        content: 'Please upload a favicon',
        onOk() { },
      });
      return;
    }



    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      "aud": process.env.REACT_APP_jwtAud,
      "exp": timestamp,
      "iss": process.env.REACT_APP_jwtIss
    };

    var token = jwt.sign(payload, process.env.REACT_APP_secret);


    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify(company)
    };

    fetch(config.get('backendURL') + 'company/add', requestOptions)
      .then(res => console.log(res.data));



    Modal.success({
      title: 'Success',
      content: 'Company details has been added',
      onOk() {
        window.location = '/welcome';
      },
    });



  }

  render() {
    const { visible, faviconvisible } = this.state;
    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Company Name</Form.Label>
            <Form.Control required type="text" value={this.state.Name} onChange={this.onChangeName} placeholder="Company Name" />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridPassword">

            <Button variant="primary" onClick={this.showDrawer.bind(this)} type="button">
              Upload Logo
            </Button>

          </Form.Group>


          <Form.Group as={Col} controlId="formfavicon">

            <Button variant="primary" onClick={this.showfaviconDrawer.bind(this)} type="button">
              Upload favicon
</Button>
          </Form.Group>

        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label>Address</Form.Label>
            <Form.Control required value={this.state.AddressLine1} onChange={this.onChangeAddressLine1} />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridAddress2">
            <Form.Label>Address 2</Form.Label>
            <Form.Control value={this.state.AddressLine2} onChange={this.onChangeAddressLine2} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>City</Form.Label>
            <Form.Control required value={this.state.City} onChange={this.onChangeCity} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>State</Form.Label>
            <Form.Control value={this.state.StateProvinceRegion} onChange={this.onChangeStateProvinceRegion} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridZip">
            <Form.Label>Zip</Form.Label>
            <Form.Control value={this.state.PostZipCode} onChange={this.onChangePostZipCode} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCountry">
            <Form.Label>Country</Form.Label>
            <Form.Control required value={this.state.Country} onChange={this.onChangeCountry} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridPhone">
            <Form.Label>Phone</Form.Label>
            <Form.Control required value={this.state.Phone} onChange={this.onChangePhone} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridFax">
            <Form.Label>Fax</Form.Label>
            <Form.Control value={this.state.Fax} onChange={this.onChangeFax} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control required type="email" value={this.state.Email} onChange={this.onChangeEmail} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridFax">
            <Form.Label>Openning Hours</Form.Label>
            <Form.Control value={this.state.OpenningHours} onChange={this.onChangeOpenningHours} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridSubDomain">
            <Form.Label>Sub Domain</Form.Label>
            <Form.Control required value={this.state.SubDomain} onChange={this.onChangeSubDomain} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCustomerDomain">
            <Form.Label>Customer Domain</Form.Label>
            <Form.Control required value={this.state.CustomerDomain} onChange={this.onChangeCustomerDomain} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridOrgId">
            <Form.Label>Organisation ID</Form.Label>
            <Form.Control required value={this.state.OrganisationID} onChange={this.onChangeOrganisationID} />
          </Form.Group>
        </Form.Row>



        <Button variant="primary" type="submit">
          Submit
  </Button>

        <Drawer
          title={this.state.itemheading}
          placement="top"
          closable={true}
          height={720}
          onClose={this.onClose}
          visible={visible}
          key="top"
        >

          <UploadItems sendData={this.getData} params={{ indexid: 0, itemid: this.state.itemid, MenuItemId: this.state.itemheading, MenuItemData: this.state.itemdata, ratiow: 3, ratioh: 2, width: 12, order: 0 }} />
        </Drawer>

        <Drawer
          title={this.state.itemheading}
          placement="top"
          closable={true}
          height={720}
          onClose={this.onfaviconClose}
          visible={faviconvisible}
          key="top"
        >

          <UploadItems sendData={this.getfaviconData} params={{ indexid: 0, itemid: this.state.itemid, MenuItemId: this.state.itemheading, MenuItemData: this.state.itemdata, ratiow: 1, ratioh: 1, width: 12, order: 0 }} />
        </Drawer>


      </Form>


    );
  }
}
